/**
 * Caution: Consider this file when using NextJS or GatsbyJS
 * 
 * You may delete this file and its occurrences from the project filesystem if you are using react-scripts
 */
 import React from 'react';
 import Cse from 'views/Cse';
 import Main from 'layouts/Main';
 import WithLayout from 'WithLayout';
 
 const Csepage = () => {
   return (
     <WithLayout
       component={Cse}
       layout={Main}
     />
   )
 };
 
 export default Csepage;